/* ===================== PROMO ======================*/
.gauto-promo-area {
  position: relative;
  background: url(../../img/promo_bg.jpg) no-repeat fixed 0 0 / cover;
  z-index: 1;
}

.gauto-promo-area:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: $color-light-blue none repeat scroll 0 0;
  z-index: -1;
}

.promo-box-left {
  position: relative;
  z-index: 1;
  padding-top: 70px;
  padding-bottom: 50px;
}

.promo-box-left:after {
  position: absolute;
  background: $color-orange none repeat scroll 0 0;
  content: "";
  top: 0;
  right: 55%;
  width: 2030px;
  height: 100%;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  z-index: -1;
}

.promo-box-left:before {
  position: absolute;
  background: #fff none repeat scroll 0 0;
  content: "";
  top: 0;
  right: 43%;
  width: 70px;
  height: 100%;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  z-index: -1;
}

.promo-box-right ul {
  color: white;
  font-size: 21px;
  line-height: 1.8;
}

.promo-box-right h3 {
  font-size: 25px;
  letter-spacing: 1px;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  line-height: 34px;
  color: $color-orange;
}

@media (min-width: 768px) and (max-width: 991px) {
  .promo-box-right {
    padding: 50px 0;
  }
  .promo-box-right h3 {
    font-size: 25px;
    line-height: 35px;
  }
}

@media (max-width: 767px) {
  .promo-box-left {
    display: none;
  }
  .promo-box-right {
    text-align: center;
  }
  .promo-box-right h3 {
    font-size: 22px;
    line-height: 31px;
  }
  .promo-box-right a.gauto-btn {
    margin-bottom: 0;
  }
  .gauto-promo-area {
    padding: 70px 0;
  }
  .promo-box-right ul {
    font-size: 16px;
    line-height: 1.7;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .promo-box-left {
    display: none;
  }
  .promo-box-right {
    text-align: center;
  }
  .promo-box-right h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .promo-box-right a.gauto-btn {
    margin-bottom: 0;
  }
}
