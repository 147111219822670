@font-face {
    font-family: 'ArnoProBold';
    src: url("./fonts/ArnoPro-BoldCaption.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ArnoProBoldItalic';
    src: url("./fonts/ArnoPro-BoldItalicCaption.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ArnoPro';
    src: url("./fonts/ArnoPro-Caption.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ArnoProItalic';
    src: url("./fonts/ArnoPro-ItalicCaption.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MariadProBold';
    src: url("./fonts/MyriadPro-Bold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MariadProCond';
    src: url("./fonts/MyriadPro-Cond.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MariadProItalic';
    src: url("./fonts/MyriadPro-It.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MariadPro';
    src: url("./fonts/MyriadPro-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MariadProSemiBold';
    src: url("./fonts/MyriadPro-Semibold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}