$color-dark-blue: #1C1A72;
$color-light-blue: rgb(16, 37, 152, 0.8);
$color-orange: #C18756;
@import "./fonts.css";
@import "./components/About/style.scss";
@import "./components/AboutPage/style.scss";
@import "./components/Blog/style.scss";
@import "./components/BlogDetails/style.scss";
@import "./components/BlogList/style.scss";
@import "./components/BlogSidebar/style.scss";
@import "./components/CarBooking/style.scss";
@import "./components/CarList/style.scss";
@import "./components/Cart/style.scss";
@import "./components/Contact/style.scss";
@import "./components/Error/style.scss";
@import "./components/findcar/style.scss";
@import "./components/Footer/style.scss";
@import "./components/Gallery/style.scss";
@import "./components/header/style.scss";
@import "./components/Help/style.scss";
@import "./components/hero/style.scss";
@import "./components/HotOffers/style.scss";
@import "./components/Login/style.scss";
@import "./components/MobileMenu/style.scss";
@import "./components/PageTitle/style.scss";
@import "./components/ProductDetails/style.scss";
@import "./components/Products/style.scss";
@import "./components/Promo/style.scss";
@import "./components/Register/style.scss";
@import "./components/Service/style.scss";
@import "./components/ServiceDetails/style.scss";
@import "./components/ServiceList/style.scss";
@import "./components/SuccessMessage/style.scss";
@import "./components/Team/style.scss";
@import "./components/Testimonial/style.scss";
@import "./components/ApplyFor/style.scss";
@import "./components/Meetings/style.scss";
@import "./components/PartnerOffers/style.scss";
@import "./components/Forum/style.scss";

@import "./main-component/App/App.scss";
