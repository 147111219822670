/* ===================== Partner offers ======================*/
.partner-offers-area.section_70{
  padding-bottom: 0;
}

.partner-offers {
  &--offer {
    .title {
      color: #1C1A72;
      font-family: "MariadProBold", sans-serif;
      font-size: 20px;

      span {
        color: $color-orange;
        padding-right: 10px;
      }
    }

    hr.devider {
      margin-top: 10px;
      margin-bottom: 20px;
      color: #1C1A72;
      height: 0;
      border-bottom: solid 1px $color-dark-blue;
    }
  }
}

@media (max-width: 767px) {
  .partner-offers {
    &--offer {
      .title {
        font-size: 17px;
      }

      hr.devider {
        margin-top: 5px;
        margin-bottom: 15px;
      }
    }
  }
}



