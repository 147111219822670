/* ===================== HERO ======================*/
.gauto-slider-area {
  position: relative;

  .promo-video {
    width: 100%;
  }
}

.gauto-slider-area .promo-banner {
  width: 100%;
}

.gauto-slider-area .chernyak .gauto-btn.gauto-btn__event-3 {
  background: $color-orange;
}

.slider-content-holder {
  width: 100%;
  text-align: center;
}

.slider-text {
  text-align: center;
  position: absolute;
  z-index: 2;
  top: 70%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.gauto-slider-area .slider-text {
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
}

.gauto-slider-area.mobile {
  height: 100px;
  background-color: #0b1534;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

.gauto-slider-area.mobile .slider-text {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

.gauto-slider-area.mobile .slider-text.chernyak {
  top: 50%;
  width: auto;
}

.slider-text.chernyak {
  top: 33%;
}

.slider-text h2 {
  color: #8b5426;
  font-size: 65px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  letter-spacing: 3px;
  line-height: 1.2;
  margin-bottom: 20px;
  font-family: "ArnoProBold", sans-serif;
  font-weight: normal;
  font-style: normal;
  white-space: pre-line;
}

.slider-text h2 span {
  color: #fff;
  font-style: italic;
  font-weight: 600;
}

.slider-text p {
  color: #fff;
  font-size: 28px;
  letter-spacing: 2px;
  margin-bottom: 15px;
  white-space: pre-line;
  line-height: 1.1;
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider-text h2 {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .slider-text {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    top: 23%;
  }
  .slider-text p {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 0px;
  }
  .slider-text h2 {
    font-size: 33px;
    line-height: 1.3;
    font-family: "ArnoProBold", sans-serif;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .slider-text {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  .slider-text p {
    font-size: 20px;
  }
  .slider-text h2 {
    font-size: 35px;
  }
}
